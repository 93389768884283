import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import type { AirtableData } from "../../graphql-types";

import {
  FilterList,
  NewsCard,
  ProposalCard,
  Select,
  TabGroup,
} from "@dcspark/grants-ui";
import { formatHTMLDescription } from "../utils/formatHtmlDescription";
import { formatDate, formatDateLocaleString } from "../utils/date";
import clsx from "clsx";

import CatalystIcon from "../../static/images/icons/grants/catalyst.inline.svg";
import AlgorandIcon from "../../static/images/icons/grants/algorand.inline.svg";
import UrbitIcon from "../../static/images/icons/grants/urbit.inline.svg";
import { LANGUAGES, Locales } from "../../locales/languages";
import { useI18next } from "gatsby-plugin-react-i18next";

const initialCategory = "All";
const initialRecent = { label: "Most Recent", value: "recent" };
const initialStatus = { label: "All", value: "all" };

type BlogPost = {
  guid: string;
  categories: string[];
  link: string;
  thumbnail: string;
  title: string;
  pubDate: string;
  description: string;
};

type Option = {
  label: string;
  value?: string;
  disabled?: boolean;
};

function NewsPage({ items }: { items: BlogPost[] }) {
  const [recent, setRecent] = React.useState<Option>(initialRecent);
  const grantsPosts = items.filter((item) => item.categories.includes("grant"));
  const filteredPosts =
    recent.value === "recent" ? grantsPosts : grantsPosts.reverse();

  return (
    <div className="w-full space-y-16 grants-container">
      <div className="flex pb-30 mb-20">
        <Select
          className="min-w-[240px]"
          value={recent}
          hideLabel={false}
          label="Sort by:"
          onChange={setRecent}
          options={[
            { label: "Most Recent", value: "recent" },
            { label: "Oldest", value: "old" },
          ]}
        />
      </div>
      <div className="space-y-60">
        {filteredPosts.map(
          ({ title, description, thumbnail, pubDate, link }, index) => (
            <NewsCard
              key={index}
              title={title}
              description={formatHTMLDescription(description)}
              imgUrl={thumbnail}
              date={formatDateLocaleString(pubDate)}
              url={link}
              linkText="Read More"
              className={clsx(
                "news gap-40 space-x-0 py-16",
                index % 2 === 0 ? "reverse" : "",
              )}
            />
          ),
        )}
      </div>
    </div>
  );
}

function ProposalPage({
  items,
}: {
  items: {
    node: {
      data: AirtableData;
    };
  }[];
}) {
  const [category, setCategory] = React.useState(initialCategory);
  const [recent, setRecent] = React.useState<Option>(initialRecent);
  const [status, setStatus] = React.useState<Option>(initialStatus);

  const proposals = items.map(({ node }) => {
    const { data } = node;
    return data;
  });

  const CatalystLength = proposals.filter(
    (item) => item.Grant_origin === "Cardano Catalyst",
  ).length;
  const UrbitLength = proposals.filter(
    (item) => item.Grant_origin === "Urbit",
  ).length;

  const options = [
    {
      label: "All",
      value: "All",
      aditionalText: `${proposals.length} Proposals`,
    },
    {
      label: "Catalyst",
      value: "Cardano Catalyst",
      icon: <CatalystIcon className="w-18 h-18 md:w-30 md:h-30" />,
      aditionalText: `${CatalystLength} Proposals`,
    },
    {
      label: "Urbit",
      value: "Urbit",
      icon: <UrbitIcon className="w-18 h-18 md:w-30 md:h-30" />,
      aditionalText: `${UrbitLength} Proposals`,
    },
  ];

  const filteredProposalByCategoryAndStatus = proposals
    .filter((item) => {
      if (category === "All") {
        return true;
      }
      return item.Grant_origin === category;
    })
    .filter((item) => {
      if (status.value === "all") {
        return true;
      }
      return item.Status?.toLowerCase() === status.value;
    });
  const filteredProposalByNewest = [
    ...filteredProposalByCategoryAndStatus,
  ].sort(
    (a, b) =>
      formatDate(b.Started_on).valueOf() - formatDate(a.Started_on).valueOf(),
  );
  const filteredProposalByLastModified = [
    ...filteredProposalByCategoryAndStatus,
  ].sort(
    (a, b) =>
      formatDate(b.Last_Modified).valueOf() -
      formatDate(a.Last_Modified).valueOf(),
  );

  const filteredProposal =
    recent.value === "recent"
      ? filteredProposalByNewest
      : recent.value === "modified"
      ? filteredProposalByLastModified
      : filteredProposalByNewest.reverse();

  return (
    <div className="w-full space-y-16 grants-container">
      <div>
        <FilterList
          groupLabel="filter"
          className="my-16 mb-40 max-w-[760px] mx-auto filter-list"
          value={category}
          // @ts-ignore
          onChange={setCategory}
          options={options}
        />
        <div className="flex flex-col md:py-30 md:pt-20 mb-35 md:mb-30 space-y-20 md:flex-row md:items-center md:space-y-0 md:space-x-32">
          <Select
            className="relative z-[2] min-w-[240px]"
            value={recent}
            hideLabel={false}
            label="Sort by:"
            onChange={setRecent}
            options={[
              { label: "Most Recent", value: "recent" },
              { label: "Last Modified", value: "modified" },
              { label: "Oldest", value: "old" },
            ]}
          />
          <Select
            className="relative z-[1] min-w-[240px]"
            value={status}
            label="Filter by Status:"
            hideLabel={false}
            onChange={setStatus}
            options={[
              { label: "All", value: "all" },
              { label: "Completed", value: "completed" },
              { label: "Ongoing", value: "ongoing" },
            ]}
          />
        </div>
      </div>
      {filteredProposal.length ? (
        filteredProposal.map((proposal, index) => {
          return <ProposalCard key={index} theme="dcspark" {...proposal} />;
        })
      ) : (
        <p className="text-center text-navy-blue">No proposals found</p>
      )}
    </div>
  );
}

const GrantsContainer = () => {
  const { language } = useI18next();
  const data = useStaticQuery<{
    dcsparkMedium: {
      items: {
        guid: string;
        categories: string[];
        link: string;
        thumbnail: string;
        title: string;
        pubDate: string;
        description: string;
      }[];
    };
    dcsparkJaMedium: {
      items: {
        guid: string;
        categories: string[];
        link: string;
        thumbnail: string;
        title: string;
        pubDate: string;
        description: string;
      }[];
    };
    allAirtable: {
      edges: {
        node: {
          data: AirtableData;
        };
      }[];
    };
  }>(graphql`
    query MyQuery {
      dcsparkMedium {
        items {
          guid
          link
          pubDate(formatString: "")
          categories
          title
          thumbnail
          description
        }
      }
      dcsparkJaMedium {
        items {
          guid
          link
          pubDate(formatString: "")
          categories
          title
          thumbnail
          description
        }
      }
      allAirtable(
        sort: { order: DESC, fields: data___Started_on }
        filter: {
          data: {
            Show_on_website: { eq: true }
            Beneficiary: { eq: "dcSpark" }
          }
        }
      ) {
        edges {
          node {
            data {
              Beneficiary
              Category
              Completed_on
              Currency
              Fund
              Description
              USD_equivalent
              Grant_origin
              Last_Modified(formatString: "YYYY-MM-DD")
              Project_Lead
              Project_name
              Started_on
              Status_desc_link1
              Status
              Status_description
              Status_title
              origin_link
            }
          }
        }
      }
    }
  `);
  const { items: ENpublications } = data.dcsparkMedium;
  const { items: JApublications } = data.dcsparkJaMedium;
  const publications =
    language === Locales.english ? ENpublications : JApublications;

  const blogs = publications.sort(
    (a, b) => new Date(b.pubDate).valueOf() - new Date(a.pubDate).valueOf(),
  );

  return (
    <>
      <section id="grant-container" className="relative lg:-mt-200">
        <div className="pt-skew absolute top-0 left-0 right-0 bottom-0">
          <div className="bg-grey-founders w-full h-full"></div>
        </div>
        <div className="bg-top-left pt-skew"></div>
        <div className="relative pt-skew">
          <div className="container relative text-navy-blue py-24">
            <TabGroup
              options={[
                {
                  name: "News",
                  component: <NewsPage items={blogs} />,
                },
                {
                  name: "Proposals",
                  component: <ProposalPage items={data.allAirtable?.edges} />,
                },
              ]}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default GrantsContainer;
