import * as React from "react";
import HeaderAndMeta from "../components/HeaderAndMeta";
import GrantsLanding from "../components/GrantsLanding";
import Footer from "../components/Footer";
import GrantsContainer from "../components/GrantsContainer";
import { graphql } from "gatsby";

const GrantPage = () => {
  return (
    <main className="grants-section">
      <HeaderAndMeta />
      <div>
        <GrantsLanding />
        <GrantsContainer />
      </div>
      <Footer />
    </main>
  );
};

export default GrantPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
